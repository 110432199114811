<template>
  <div>
    <div v-for="(item,index) in dataList" class="terminalInfo-contant-box" :key="index">
      <div class="terminalInfo-contant">
        <div class="terminalInfo-contact-item">
          <span class="terminalInfo-contact-item-title">供货人</span>
          <el-input disabled v-model="item.salesman"></el-input>
        </div>
        <div class="terminalInfo-contact-item">
          <span class="terminalInfo-contact-item-title">供货组织</span>
          <el-input disabled v-model="item.salesmanOrganization"></el-input>
        </div>
        <div class="terminalInfo-contact-item">
          <span class="terminalInfo-contact-item-title">上级客户</span>
          <el-input disabled v-model="item.superiorCustomer"></el-input>
        </div>

      </div>
      <div class="w100">
        <vxe-table border align="center" :data="item.details">
          <!-- <vxe-table-column field="dataType" title="类型" width="30%">
            <template v-slot="{ row }">
              <div class="setting-btn">
                {{row.dataType==1?'商品':'系列'}}
              </div>
            </template>
          </vxe-table-column> -->
          <vxe-table-column field="code" title="编码" width="50%"></vxe-table-column>
          <vxe-table-column field="name" title="名称" width="50%"></vxe-table-column>
        </vxe-table>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: Array,
  },
  name: 'Supplys',
  data() {
    return {
      dataList: [
        {
          supplyType: 'default',
          customerCode: '',
          dataType: '',
          orgCode: '',
          positionCode: '',
          details: [],
          positionList: [],
        },
      ],
    };
  },
  watch: {
    value() {
      this.dataList = this.value;
    //   this.dataList.forEach((item, index) => {
    //     if (!Array.isArray(this.dataList[index].positionList)) {
    //       const positionList = [
    //         {
    //           positionCode: this.dataList[index].positionCode,
    //           unionName: `${this.dataList[index].orgName}/${
    //             this.dataList[index].positionName
    //           }/${this.dataList[index].fullName}`,
    //         },
    //       ];
    //       this.$set(this.dataList[index], 'positionList', positionList);
    //       this.dataList[index].positionCode = item.positionCode;
    //     }
    //   });
    },
  },

  created() {},
  methods: {},
};
</script>

<style lang = "less" scoped>
/deep/.el-button--small.is-circle {
  padding: 5px;
  margin-right: 8px;
}
/deep/.terminalInfo-contact-item-title {
  padding: 0px 10px;
  text-align: right;
}
.supplys-title {
  /* border-top: 1px solid #aeaeae; */
  /* padding-top: 0.5rem; */
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 600;
  .addBtn {
    cursor: pointer;
    color: #409eff;
    margin-left: 5px;
    font-size: 16px;
  }
}
.terminalInfo-contant-box {
  margin-bottom: 19px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 2.5rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  .terminalInfo-contant {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    .terminalInfo-contact-item {
      /* flex:1; */
      display: flex;
      width: 33.33%;
      margin-bottom: 18px;
      .terminalInfo-contact-item-title {
        width: 125px;
        color: #606266;
      }
    }
  }
  .terminalInfo-contant-delete {
    width: 5%;
    i {
      color: #409eff;
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .terminalInfo-supplys-btn-group {
    width: 100%;
    .terminalInfo-supplys-btn {
      color: #1c92ff;
      &:first-child {
        margin-right: 0.5rem;
      }
    }
  }
  .w100 {
    width: 100%;
  }
}
</style>
