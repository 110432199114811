/*
 * @Author: 冯杰
 * @Date: 2021-07-22 10:33:34
 * @LastEditTime: 2021-09-07 14:47:38
 * @LastEditors: Please set LastEditors
 * @Description: 分利终端详情页
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
// 导入自定义组件
import TerminalDetails from './terminal-details.vue';
import DetailForm from './component/detail/detail-form.js';
import StatisicalForm from './component/statisical/statistical-form.js';

// 注册(自定义)组件
formCreate.component('TerminalDetails', TerminalDetails);
formCreate.component('DetailForm', DetailForm);
formCreate.component('StatisicalForm', StatisicalForm);

export default {
  extends: Form,
  components: {
    // 挂载组件
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'myForm',
      buttons: {
        submit: false,
        close: true,
        submitAudit: false,
      },
    };
  },
  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'detail' || item.field === 'statistics') {
        item.props = {
          ...item.props,
          rowData: this.formConfig,
        };
      }
      return item;
    },
    formComplete() {
      this.hiddenFields(true, 'statistics');
      const formdetail = this.getRule('formdetail');

      formdetail.on = {
        ...formdetail.on,
        tabName: (name) => {
          // 切换到基本信息
          if (name === 'deatil') {
            this.hiddenFields(false, 'detail');
            this.hiddenFields(true, 'statistics');
          }
          // 切换到分利统计信息
          if (name === 'statistics') {
            this.hiddenFields(false, 'statistics');
            this.hiddenFields(true, 'detail');
          }
        },
      };
    },

    // 提交
    submit() {},
  },
};
