/*
 * @Author: 冯杰
 * @Date: 2021-07-22 10:33:34
 * @LastEditTime: 2021-09-10 15:00:35
 * @LastEditors: Please set LastEditors
 * @Description: 准入已有终端页
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import AccessList from './access-list.js';

formCreate.component('AccessList', AccessList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [
        {
          type: 'AccessList',
          field: 'accessList',
          title: '',
        },
      ],
      formFunctionCode: 'accessForm',
    };
  },
  methods: {
    // 提交
    submit() {
      const accessEl = this.fApi.el('accessList');
      const formData = accessEl.selectRow;

      if (formData) {
        const url = '/cps/v1/participator/terminal/createBatch';
        const params = formData;
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '保存成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
