/*
 * @Author: 冯杰
 * @Date: 2021-07-22 10:33:34
 * @LastEditTime: 2021-11-02 19:57:07
 * @LastEditors: Please set LastEditors
 * @Description: 分利终端列表
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';
import Form from './form';

import Access from './form/component/access/access.js';

export default {
  name: 'TerminalList',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/web/terminalReport/findByConditions',
      requestType: 'GET',
    };
  },
  components: {
    Modal,
    Form,
    Access,
  },
  mounted() {
    this.getPath();
  },
  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      let urls = '';
      let text = '';
      let id = '';
      this.formConfig = {
        ...this.formConfig,
        code: val.code,
      };
      if (val.code === 'zhongduan') {
        // 打开准入已有终端页
        this.modalConfig.title = '准入已有终端';
        this.formName = 'Access';
        this.formConfig.row = row;
        this.formConfig.buttonCode = 'zhongduan';
        this.openFull();
      } else if (val.code === 'particulars') {
        // 打开终端详情页
        this.modalConfig.title = '终端详情';
        this.formName = 'Form';
        this.formConfig = row;
        this.formConfig.buttonCode = 'particulars';
        this.openFull();
      } else if (val.code === 'view') {
        // 查看
        this.modalConfig.title = '查看';
        this.formName = 'Form';
        this.formConfig = row;
        this.openFull();
      } else if (val.code === 'approve') {
        // 审批
        urls = '/cps/v1/participator/terminal/pass/';
        text = '审批';
        id = this.selection;
        this.ReviewJejected(urls, id, text);
      } else if (val.code === 'overrule') {
        // 驳回
        urls = '/cps/v1/participator/terminal/cancel/';
        text = '驳回';
        id = this.selection;
        this.ReviewJejected(urls, id, text);
      }
      if (val.code === 'enable' || val.code === 'span-qi') {
        // 启用
        urls = '/cps/v1/participator/terminal/enable';
        text = '启用';
        if (row.id) {
          this.selection.push(row.id);
        }
        this.operationClick(urls, text);
      } else if (val.code === 'disable' || val.code === 'span-jin') {
        // 禁用
        urls = '/cps/v1/participator/terminal/disable';
        text = '禁用';
        if (row.id) {
          this.selection.push(row.id);
        }
        this.operationClick(urls, text);
      }
    },
    // 启用、禁用操作
    operationClick(urls, text) {
      let datas = [];
      if (this.selection.length > 0) {
        datas = this.selection;
        request.patch(urls, datas).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.selection = [];
            this.getList();
          }
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择数据',
        });
      }
    },
    // 审核、驳回操作
    ReviewJejected(urls, id, text) {
      console.log(id);
      if (id) {
        request.patch(urls + id).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.selection = [];
            this.getList();
          }
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择数据',
        });
      }
    },
    // 设置下拉选项的值
    setColumn(v) {
      const rowData = v;
      // 客户组织
      // if (rowData.field === 'customerOrganization') {
      //   // rowData.search = 'true';
      //   rowData.type = 'select';
      //   rowData.apiUrl = '/mdm/baseTreeController/customerOrgTree';
      //   rowData.optionsKey = {
      //     label: 'name',
      //     value: 'code',
      //   };
      //   // rowData.isSearch = true;
      //   rowData.paramsName = 'name';
      //   rowData.paramsFixed = {
      //     enableStatus: '009',
      //   };
      // }
      // 所属组织;
      // if (rowData.field === 'organization') {
      //   rowData.search = 'true';
      //   rowData.type = 'select';
      //   rowData.apiUrl = '/mdm/baseTreeController/orgTree';
      //   rowData.optionsKey = {
      //     label: 'name',
      //     value: 'code',
      //   };
      //   rowData.isSearch = true;
      //   rowData.paramsName = 'name';
      //   rowData.paramsFixed = {
      //     enableStatus: '009',
      //   };
      // }
      return rowData;
    },
    // 对列表数据进行二次封装
    afterGetList() {
      if (this.tableData.length > 0) {
        const newTableData = this.tableData.map((item) => ({
          ...item,
          enableStatus: item.enableStatus === '003' ? '禁用' : '启用',
        }));
        this.tableData = newTableData;
      }
    },
    // 页面跳转
    getPath() {
      if (this.$route.name === 'terminal') {
        this.formData.terminalCode = this.$route.params.terminalCode;
        this.searchFormData = {
          ...this.searchFormData,
          terminalCode: this.$route.params.terminalCode,
        };
        console.log(this.formData);
        this.getList();
      }
    },
  },
  watch: {
    $route: 'getPath',
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('myList');
  },
};
