/*
 * @Author: 冯杰
 * @Date: 2021-07-16 14:39:18
 * @LastEditTime: 2021-10-08 15:24:05
 * @LastEditors: chenlei
 * @Description: 分利统计/分利协议表格
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';

export default {
  name: 'text',
  extends: TablePage,
  props: {
    code: String,
  },
  data() {
    console.log(this);
    return {
      requestUrl: '/cps/v1/web/participatorReward/findByConditions',
      requestType: 'GET',
      params: {
        code: this.code || '',
      },
    };
  },
  components: {
    Modal,
  },

  methods: {},
  created() {
    // 重写获取配置信息
    this.getConfigList('protocol');
  },
};
