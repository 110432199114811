/*
 * @Author: 冯杰
 * @Date: 2021-07-16 14:17:53
 * @LastEditTime: 2021-10-14 15:11:02
 * @LastEditors: Please set LastEditors
 * @Description: 分利终端详情/分利统计信息
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import StatisicalFormTable from './statisical-form-table.js';

formCreate.component('StatisicalFormTable', StatisicalFormTable);

export default {
  extends: Form,
  props: {
    rowData: Object,
  },
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      buttons: {
        submit: false,
        close: false,
        submitAudit: false,
      },
      formFunctionCode: 'statistics',
      fields: [
        'accessTime',
        'agreementTotal',
        'cashAvailable',
        'cashTotal',
        'integralAvailable',
        'integralTotal',
        'orderAmountTotal',
        'orderTotal',
        'redPacketTotal',
        'scanTotal',
        'costTotal',
        'costAvailable',
        'redPacketAvailable',
      ],
    };
  },
  methods: {
    setRule(v) {
      const item = v;

      if (item.field === 'StatisicalFormTable') {
        item.props = {
          ...item.props,
          code: this.rowData.terminalCode,
        };
      }

      return item;
    },
    formComplete() {
      this.disabled(true, this.fields);
      this.gettrmimenedata();
    },
    submit() {},
    // 根据查询数据
    gettrmimenedata() {
      request
        .get('/cps/v1/web/participatorReport/findByParticipatorCodeAndType', {
          participatorCode: this.rowData.terminalCode,
          participatorType: 2,
        })
        .then((res) => {
          if (!res.success) return;
          const obj = res.result;
          console.log(res.result);
          this.setValue({
            ...obj,
          });
        });
    },
  },
};
