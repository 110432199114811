var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.dataList, function (item, index) {
      return _c(
        "div",
        { key: index, staticClass: "terminalInfo-contant-box" },
        [
          _c("div", { staticClass: "terminalInfo-contant" }, [
            _c(
              "div",
              { staticClass: "terminalInfo-contact-item" },
              [
                _c("span", { staticClass: "terminalInfo-contact-item-title" }, [
                  _vm._v("供货人"),
                ]),
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: item.salesman,
                    callback: function ($$v) {
                      _vm.$set(item, "salesman", $$v)
                    },
                    expression: "item.salesman",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "terminalInfo-contact-item" },
              [
                _c("span", { staticClass: "terminalInfo-contact-item-title" }, [
                  _vm._v("供货组织"),
                ]),
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: item.salesmanOrganization,
                    callback: function ($$v) {
                      _vm.$set(item, "salesmanOrganization", $$v)
                    },
                    expression: "item.salesmanOrganization",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "terminalInfo-contact-item" },
              [
                _c("span", { staticClass: "terminalInfo-contact-item-title" }, [
                  _vm._v("上级客户"),
                ]),
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: item.superiorCustomer,
                    callback: function ($$v) {
                      _vm.$set(item, "superiorCustomer", $$v)
                    },
                    expression: "item.superiorCustomer",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "w100" },
            [
              _c(
                "vxe-table",
                { attrs: { border: "", align: "center", data: item.details } },
                [
                  _c("vxe-table-column", {
                    attrs: { field: "code", title: "编码", width: "50%" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "name", title: "名称", width: "50%" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }