<!--
 * @Author: 冯杰
 * @Date: 2021-08-26 16:31:57
 * @LastEditTime: 2021-08-30 16:12:22
 * @FileName: 图片展示
-->
<template>
  <div class="block">
    <el-image :src="urlPatch" :preview-src-list="srcList">
      <div slot="error" class="image-solt">
        <i class="el-icon-picture-outline"></i>
      </div>
    </el-image>
  </div>
</template>

<script>
export default {
  data() {
    return {
    //   url: 'http://192.168.1.115:8080/47699fa2-3034-48c2-8e37-129f59d19d84',
      urlPatch: this.url,
      srcList: [this.url],
    };
  },
  props: {
    url: String,
  },

};
</script>

<style lang="less" scoped>
.el-image {
  width: 58px !important;
  height: 58px !important;
  /deep/ .image-solt {
    width: 100%;
    height: 100%;
    text-align: center;
    // background-color: #F5F7FA;
    border: 2px dashed #E4E7ED;
    i{
        font-size: 26px;
        color: #C0C4CC;
        transform: translateY(50%);
    }
  }
}
</style>
