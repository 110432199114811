/*
 * @Author: 冯杰
 * @Date: 2021-07-16 11:25:50
 * @LastEditTime: 2021-09-30 14:01:19
 * @LastEditors: yzr
 * @Description: 分利终端详情/基本信息
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import ImageCheck from '../../../../../components/imageCheck.vue';
import supplyRelationships from '../biz-relationship/biz-relationship.vue';

formCreate.component('ImageCheck', ImageCheck);

formCreate.component('supplyRelationships', supplyRelationships);

export default {
  extends: Form,
  props: {
    rowData: Object,
  },
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      buttons: {
        submit: false,
        close: false,
        submitAudit: false,
      },
      formFunctionCode: 'detail',
      data: {},
      fields: [
        'terminalName',
        'terminalType',
        'participatorTags',
        'organization',
        'customerOrgCode',
        'channel',
        'legalPersonSName',
        'registrationNumber',
        'companyName',
        'provinceName',
        'cityName',
        'districtName',
        'terminalAddress',
        'contactPersonName',
        'contactPersonPhone',
        'salesman',
        'salesmanOrganization',
        'superiorCustomer',
        'shopSignPath',
        'businessLicensePath',
        'createAccount',
        'createTime',
        'auditStatus',
      ],
    };
  },
  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'shopSignPath') {
        item.props = {
          ...item.props,
          url: this.rowData.shopSignPath,
        };
      }
      if (item.field === 'businessLicensePath') {
        item.props = {
          ...item.props,
          url: this.rowData.businessLicensePath,
        };
      }
      return item;
    },

    // 根据id查询数据
    gettrmimenedata() {
      request.get('/cps/v1/participator/terminal/findDetailsById', {
        id: this.rowData.id,
      })
        .then((res) => {
          if (!res.success) return;
          const obj = res.result;
          console.log(this.data);
          // if (obj.supplyRelationships) {
          //   obj.supplyRelationships.forEach((item) => {
          //     obj.salesman = item.salesman;
          //     obj.salesmanOrganization = item.salesmanOrganization;
          //     obj.superiorCustomer = item.superiorCustomer;
          //   });
          // }
          if (obj.contactPersons) {
            obj.contactPersons.forEach((item) => {
              obj.contactPersonName = item.contactPersonName;
              obj.contactPersonPhone = item.contactPersonPhone;
            });
          }
          this.setValue({
            ...obj,
          });
        });
    },
    // 根据code查询数据
    // queryRedpacket() {
    //   request
    //     .get('/cps/v1/participator/terminal/findDetailsByTerminalCode', {
    //       terminalCode: this.rowData.code,
    //     })
    //     .then((res) => {
    //       if (!res.success) return;
    //       this.setValue({
    //         ...res.result,
    //       });
    //     });
    // },
    formComplete() {
      // 禁用字段
      this.disabled(true, this.fields);
      this.gettrmimenedata();
    },
  },
};
