<!--
 * @Author: 冯杰
 * @Date: 2021-07-14 16:09:26
 * @LastEditTime: 2021-09-29 16:26:27
 * @LastEditors: yzr
 * @Description: 公共文件navbar
-->
<template>
  <div class="particulars-continaer">
    <!-- 分利终端详情 -->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="deatil">
      </el-tab-pane>
      <el-tab-pane label="分利统计信息" name="statistics">
      </el-tab-pane>
      <!-- <el-tab-pane label="虚拟账户信息（个体工商户版）" name="individual">
      </el-tab-pane>
      <el-tab-pane label="虚拟账户信息（企业版）" name="enterprise">
      </el-tab-pane>
      <el-tab-pane label="虚拟账户信息（个人版）" name="person">
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'deatil',
    };
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name;
      this.$emit('tabName', this.activeName);
    },
  },
};
</script>

<style lang="less" scoped>
// .particulars-continaer {
//   background-color: #f0f2f5;

//   ::v-deep .el-tabs__header {
//     margin-bottom: 0;
//     .el-tabs__nav {
//       height: 50px;
//       margin-left: 50px;
//       .el-tabs__item {
//         font-size: 18px;
//         color: #00abed;
//       }
//     }
//   }

//   .tab-list {
//     width: 100%;
//     justify-content: space-between;
//     background-color: #fcfcfd;
//     border: 1px solid #e4e4e4;
//     .el-link {
//       font-weight: 700;
//       font-size: 18px;
//       padding-left: 50px;
//     }
//   }

// }
</style>
