var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "block" },
    [
      _c(
        "el-image",
        { attrs: { src: _vm.urlPatch, "preview-src-list": _vm.srcList } },
        [
          _c(
            "div",
            {
              staticClass: "image-solt",
              attrs: { slot: "error" },
              slot: "error",
            },
            [_c("i", { staticClass: "el-icon-picture-outline" })]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }